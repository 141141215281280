import type { AppData } from '../../viewer.app';
import { demoRichContent } from './demo-rich-content';
import model from './model';

export default model.createController(({ $w, flowAPI, appData }) => {
  return {
    pageReady: async () => {
      const richContentViewer = $w(
        '#richContentViewer1',
      ) as $w.RichContentViewer;

      const paywallRichContentViewer = $w(
        '#richContentViewer2',
      ) as $w.RichContentViewer;

      paywallRichContentViewer?.delete();

      const metaSiteId =
        flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
      const isMobileView = flowAPI.environment.isMobile;
      const content = flowAPI.environment.isEditor
        ? demoRichContent
        : (await (appData as AppData).fetchPostPageRenderModel()).post
            .richContent;

      richContentViewer.metaSiteId = metaSiteId;
      richContentViewer.isMobileView = isMobileView;
      richContentViewer.content = content;
    },
    exports: {},
  };
});
